import React, { Component } from "react";
import {
  saveOrUpdateCustomPromptPair,
  searchCustomPromptPair,
  deleteCustomPromptPair, // Import delete function
} from "../../../api/rest-apis";
import { toastHelper } from "../../../helpers/toast";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  characterId: string;
  assistantId: string;
  prompt: string;
}

interface ModalState {
  message: string;
  isLoading: boolean;
  error: string | null;
}

class CustomPromptModal extends Component<ModalProps, ModalState> {
  constructor(props: ModalProps) {
    super(props);
    this.state = {
      message: "",
      isLoading: false,
      error: null,
    };
  }

  componentDidMount() {
    this.loadExistingPrompt();
  }

  componentDidUpdate(prevProps: ModalProps) {
    if (
      prevProps.characterId !== this.props.characterId ||
      prevProps.assistantId !== this.props.assistantId ||
      prevProps.prompt !== this.props.prompt
    ) {
      this.loadExistingPrompt();
    }
  }

  loadExistingPrompt = async () => {
    const { characterId, assistantId, prompt } = this.props;

    try {
      this.setState({ isLoading: true });
      const result: any = await searchCustomPromptPair(
        characterId,
        assistantId,
        prompt
      );
      console.log(result);
      this.setState({ message: result.data.response || "" });
    } catch (error) {
      console.error("Error loading prompt:", error);
      this.setState({ message: "" });

      // this.setState({ error: "Error loading prompt" });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ message: e.target.value });
  };

  handleSave = async () => {
    const { characterId, assistantId, prompt, onClose } = this.props;
    const { message } = this.state;

    try {
      this.setState({ isLoading: true });
      await saveOrUpdateCustomPromptPair(
        characterId,
        assistantId,
        prompt,
        message
      );
      toastHelper.success("Prompt saved/updated successfully.");

      onClose();
    } catch (error) {
      toastHelper.error("Error saving/updating prompt:", error);
      this.setState({ error: "Error saving/updating prompt" });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleDelete = async () => {
    const { characterId, assistantId, prompt, onClose } = this.props;

    try {
      this.setState({ isLoading: true });
      await deleteCustomPromptPair(characterId, assistantId, prompt);
      toastHelper.success("Prompt deleted successfully.");

      onClose();
    } catch (error) {
      toastHelper.error("Error deleting prompt:", error);
      this.setState({ error: "Error deleting prompt" });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isOpen, onClose } = this.props;
    const { message, isLoading, error } = this.state;

    if (!isOpen) {
      return null;
    }

    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
        <div className="bg-white w-full max-w-4xl mx-auto p-6 rounded-lg shadow-lg">
          <h2 className="text-lg font-semibold mb-4">
            Enter your response message
          </h2>
          {isLoading && <p>Loading...</p>}
          {error && <p className="text-red-500">{error}</p>}
          <textarea
            value={message}
            onChange={this.handleInputChange}
            className="w-full p-2 border border-gray-300 rounded mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Type your message here..."
            rows={12}
          />
          <div className="flex justify-end space-x-2">
            <button
              onClick={this.handleDelete}
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 mr-auto"
              disabled={isLoading}
            >
              {isLoading ? "Deleting..." : "Delete"}
            </button>
            <button
              onClick={onClose}
              className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              onClick={this.handleSave}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              disabled={isLoading}
            >
              {isLoading ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomPromptModal;
