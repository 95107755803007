import React, { Component } from "react";
import { Link } from "react-router-dom";
import { accountSignupForm } from "../../api/rest-apis";
import Header from "../../components/header";
import ageGroups from "../../helpers/ages";
import countries from "../../helpers/countries";
import interests from "../../helpers/interests";
import Spin from "../../helpers/spin";
import stories from "../../helpers/stories";
import { toastHelper } from "../../helpers/toast";
import withRouter from "../../helpers/withRouter";

interface SignupProps {
  user_id: string;
}

interface SignupState {
  firstName: string;
  lastName: string;
  address: {
    addr1?: string;
    addr2?: string;
    city?: string;
    state?: string;
    zip?: string;
    country?: string;
  };
  interests: {
    chat?: boolean;
    date?: boolean;
    flirt?: boolean;
    sexyTalk?: boolean;
  };
  gender: string;
  age: string;
  favoriteStories: {
    m_m?: boolean;
    m_m_f?: boolean;
    m_f_m?: boolean;
    m_f?: boolean;
    interracial?: boolean;
    romanticErotica?: boolean;
    daddyStories?: boolean;
    mommyStories?: boolean;
    stepSiblingStories?: boolean;
    wolfShifter?: boolean;
    bdsm?: boolean;
    newAdult?: boolean;
    romanticSuspense?: boolean;
    romanticComedy?: boolean;
    suspenseThrillerMystery?: boolean;
    audiobooks?: boolean;
    selfHelp?: boolean;
  };
  receiveUpdates: boolean;
  whereDidYouHear: string;
  loading: boolean;
}

class Signup extends React.Component<SignupProps, SignupState> {
  state = {
    loading: false,
    firstName: "",
    lastName: "",
    address: {
      addr1: undefined,
      addr2: undefined,
      city: undefined,
      state: undefined,
      zip: undefined,
      country: undefined,
    },
    interests: {
      chat: false,
      date: false,
      flirt: false,
      sexyTalk: false,
    },
    gender: "",
    age: "",
    favoriteStories: {
      m_m: false,
      m_m_f: false,
      m_f_m: false,
      m_f: false,
      interracial: false,
      romanticErotica: false,
      daddyStories: false,
      mommyStories: false,
      stepSiblingStories: false,
      wolfShifter: false,
      bdsm: false,
      newAdult: false,
      romanticSuspense: false,
      romanticComedy: false,
      suspenseThrillerMystery: false,
      audiobooks: false,
      selfHelp: false,
    },
    receiveUpdates: false,
    whereDidYouHear: "",
  };

  handleAgeChange = (e: any) => {
    this.setState({ age: e.target.value });
  };

  handleGenderChange = (e: any) => {
    this.setState({ gender: e.target.id }); // Update gender state with the id of the clicked radio button
  };

  // handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   this.setState({ [name]: value });
  // };

  handleSkip = async () => {
    const id = toastHelper.loading("Skipping ...");
    try {
      const payload: any = { skip: true, user_id: this.props.user_id };
      const response = await accountSignupForm(payload);
      window.location.href = `${process.env.REACT_APP_REST_API_URI}/login`;
      toastHelper.success("Done", id);
    } catch (error) {
      console.error(error);
      toastHelper.error("Couldn't Skip", id);

    }
  };

  handleSubmit = async () => {
    this.setState({ loading: true });
    try {
      const payload: any = { ...this.state, user_id: this.props.user_id };
      const response = await accountSignupForm(payload);
      window.location.href = `${process.env.REACT_APP_REST_API_URI}/login`;
      toastHelper.success("Thank you for subscribing!");
    } catch (error: any) {
      console.error(error);
      toastHelper.error(error.response.data.errors[0]);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    return (
      <div>
        <Header></Header>

        <div className="container">
          <div>
            <div className="flex items-center">
              {" "}
              <div className="form-title" style={{ textAlign: "start" }}>
                Tell us about yourself
              </div>
              <button
                className="ml-3"
                style={{ color: "#0616F9", fontWeight: 600 }}
                onClick={() => this.handleSkip()}
              >
                {" "}
                &gt; &gt; Skip
              </button>
            </div>
            <div className="bookish-input-group mt-4">
              <p className="bookish-input-label">
                First Name <span className="required">*</span>
              </p>
              <input
                type="text"
                className="bookish-input-field"
                name="firstName"
                value={this.state.firstName}
                onChange={(e) => this.setState({ firstName: e.target.value })}
                required
              />
            </div>

            <div className="bookish-input-group mt-4">
              <p className="bookish-input-label">
                Last Name <span className="required">*</span>
              </p>
              <input
                type="text"
                className="bookish-input-field"
                name="lastName"
                value={this.state.lastName}
                onChange={(e) => this.setState({ lastName: e.target.value })}
                required
              />
            </div>

            <div className="bookish-input-group mt-4">
              <p className="bookish-input-label">Address</p>
              <input
                type="text"
                className="bookish-input-field"
                name="addr1"
                value={this.state.address?.addr1}
                onChange={(e) =>
                  this.setState({
                    address: { ...this.state.address, addr1: e.target.value },
                  })
                }
              />
            </div>

            <div className="bookish-input-group mt-4">
              <p className="bookish-input-label">Address Line 2</p>
              <input
                type="text"
                className="bookish-input-field"
                name="addr2"
                value={this.state.address?.addr2}
                onChange={(e) =>
                  this.setState({
                    address: { ...this.state.address, addr2: e.target.value },
                  })
                }
              />
            </div>

            <div className="bookish-input-group mt-4">
              <p className="bookish-input-label">City</p>
              <input
                type="text"
                className="bookish-input-field"
                name="city"
                value={this.state.address?.city}
                onChange={(e) =>
                  this.setState({
                    address: { ...this.state.address, city: e.target.value },
                  })
                }
              />
            </div>

            <div className="bookish-input-group mt-4">
              <p className="bookish-input-label">State/Province/Region</p>
              <input
                type="text"
                className="bookish-input-field"
                name="state"
                value={this.state.address?.state}
                onChange={(e) =>
                  this.setState({
                    address: { ...this.state.address, state: e.target.value },
                  })
                }
              />
            </div>

            <div className="bookish-input-group mt-4">
              <p className="bookish-input-label">Postal / Zip Code</p>
              <input
                type="text"
                className="bookish-input-field"
                name="zip"
                value={this.state.address?.zip}
                onChange={(e) =>
                  this.setState({
                    address: { ...this.state.address, zip: e.target.value },
                  })
                }
              />
            </div>

            <div className="bookish-input-group mt-4">
              <div className="sm:col-span-3">
                <p className="bookish-input-label">
                  Country <span className="required">*</span>
                </p>
                <div className="mt-2">
                  <select
                    id="country"
                    autoComplete="country-name"
                    className="bookish-dropdown-field"
                    name="country"
                    value={this.state.address?.country}
                    onChange={(e) =>
                      this.setState({
                        address: {
                          ...this.state.address,
                          country: e.target.value,
                        },
                      })
                    }
                  >
                    {countries.map((value: any) => (
                      <option>{value.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="bookish-input-group mt-4">
              <p className="bookish-input-label">
                What are you interested in doing with it?
              </p>
              <div className="flex gap-8 flex-wrap">
                {interests.map((interest: { key: string; name: string }) => (
                  <div key={interest.key} className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        id={interest.key}
                        name={interest.key}
                        type="checkbox"
                        onChange={(e) =>
                          this.setState({
                            interests: {
                              ...this.state.interests,
                              [interest.key]: e.target.checked,
                            },
                          })
                        }
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        checked={
                          interest.key === "chat"
                            ? this.state.interests.chat
                            : interest.key === "date"
                            ? this.state.interests.date
                            : interest.key === "flirt"
                            ? this.state.interests.flirt
                            : this.state.interests.sexyTalk
                        }
                      />
                    </div>
                    <div className="text-sm leading-6">
                      <label
                        htmlFor={interest.key}
                        className="font-medium text-gray-900"
                      >
                        {interest.name}
                      </label>
                    </div>
                  </div>
                ))}
              </div>

              <div className="bookish-input-group mt-8">
                <p className="bookish-input-label">
                  What type of stories do you like most?
                </p>
                <div className="grid grid-cols-2 gap-4">
                  {stories.map((value: any) => (
                    <div className="relative flex gap-x-3">
                      <div className="flex h-6 items-center">
                        <input
                          id={value.key}
                          name={value.key}
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          onChange={(e) =>
                            this.setState({
                              favoriteStories: {
                                ...this.state.favoriteStories,
                                [value.key]: e.target.checked,
                              },
                            })
                          }
                          checked={
                            value.key === "m_m"
                              ? this.state.favoriteStories.m_m
                              : value.key === "m_m_f"
                              ? this.state.favoriteStories.m_m_f
                              : value.key === "m_f_m"
                              ? this.state.favoriteStories.m_f_m
                              : value.key === "m_f"
                              ? this.state.favoriteStories.m_f
                              : value.key === "interracial"
                              ? this.state.favoriteStories.interracial
                              : value.key === "romanticErotica"
                              ? this.state.favoriteStories.romanticErotica
                              : value.key === "daddyStories"
                              ? this.state.favoriteStories.daddyStories
                              : value.key === "mommyStories"
                              ? this.state.favoriteStories.mommyStories
                              : value.key === "stepSiblingStories"
                              ? this.state.favoriteStories.stepSiblingStories
                              : value.key === "wolfShifter"
                              ? this.state.favoriteStories.wolfShifter
                              : value.key === "bdsm"
                              ? this.state.favoriteStories.bdsm
                              : value.key === "newAdult"
                              ? this.state.favoriteStories.newAdult
                              : value.key === "romanticSuspense"
                              ? this.state.favoriteStories.romanticSuspense
                              : value.key === "romanticComedy"
                              ? this.state.favoriteStories.romanticComedy
                              : value.key === "suspenseThrillerMystery"
                              ? this.state.favoriteStories
                                  .suspenseThrillerMystery
                              : value.key === "audiobooks"
                              ? this.state.favoriteStories.audiobooks
                              : value.key === "selfHelp"
                              ? this.state.favoriteStories.selfHelp
                              : false
                          }
                        />
                      </div>
                      <div className="text-sm leading-6">
                        <label
                          htmlFor="mm"
                          className="font-medium text-gray-900"
                        >
                          {value.name}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="bookish-input-group mt-8">
                <p className="bookish-input-label">
                  Gender <span className="required">*</span>
                </p>
                <div className="flex gap-8 flex-wrap">
                  <div className="flex items-center gap-x-3">
                    <input
                      id="Female"
                      name="gender"
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      onChange={this.handleGenderChange}
                      checked={this.state.gender === "Female"}
                    />
                    <label
                      htmlFor="Female"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Female
                    </label>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <input
                      id="Male"
                      name="gender"
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      onChange={this.handleGenderChange}
                      checked={this.state.gender === "Male"}
                    />
                    <label
                      htmlFor="Male"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Male
                    </label>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <input
                      id="Other"
                      name="gender"
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      onChange={this.handleGenderChange}
                      checked={this.state.gender === "Other"}
                    />
                    <label
                      htmlFor="Other"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Other
                    </label>
                  </div>
                </div>
              </div>

              <div className="bookish-input-group mt-8">
                <p className="bookish-input-label">
                  Age <span className="required">*</span>
                </p>
                <div className="grid grid-cols-3">
                  {ageGroups.map((ageGroup: any) => (
                    <div
                      key={ageGroup.id}
                      className="flex items-center gap-x-3 flex-wrap"
                    >
                      <input
                        id={ageGroup.id}
                        name="age-group"
                        type="radio"
                        value={ageGroup.id}
                        checked={this.state.age === ageGroup.id}
                        onChange={this.handleAgeChange}
                        className="h-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label
                        htmlFor={ageGroup.id}
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        {ageGroup.label}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="bookish-input-group mt-8">
                <p className="bookish-input-label">
                  What else would you like to receive?
                </p>
                <div className="flex gap-8 flex-wrap">
                  <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        id="receiveUpdates"
                        name="receiveUpdates"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        onChange={(e) =>
                          this.setState({
                            receiveUpdates: e.target.checked,
                          })
                        }
                        checked={this.state.receiveUpdates}
                      />
                    </div>
                    <div className="text-sm leading-6">
                      <label
                        htmlFor="receiveUpdates"
                        className="font-medium text-gray-900"
                      >
                        I would like to receive updates on all authors hosting Chatbots
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bookish-input-group mt-8">
                <p className="bookish-input-label">
                  Where did you hear about us?
                </p>
                <input
                  type="text"
                  className="bookish-input-field"
                  name="addr1"
                  value={this.state.whereDidYouHear}
                  onChange={(e) =>
                    this.setState({
                      whereDidYouHear: e.target.value,
                    })
                  }
                />
              </div>

              <div className="bookish-input-group mt-4">
                <button
                  className="bookish-primary-btn"
                  onClick={() => this.handleSubmit()}
                >
                  {this.state.loading && (
                    <div className={"flex justify-center"}>
                      <Spin></Spin>
                      <span style={{ color: "#fff" }}>Processing...</span>
                    </div>
                  )}

                  {!this.state.loading && (
                    <span style={{ color: "#fff" }}>Continue</span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Signup);
