import React, { useState, useEffect } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/home/home";
import Favourite from "./pages/home/favourite";
import Signup from "./pages/home/Signup";
import Login from "./pages/home/Login";
import ChatPage from "./pages/home/ChatPage";
import AdminHome from "./pages/admin/AdminHome";
import AdminLogin from "./pages/admin/pages/Login";
import EditCharacter from "./pages/admin/pages/EditCharacter";
import CreateCharacter from "./pages/admin/pages/CreateCharacter";
import SingleCharacterAnalytics from "./pages/admin/pages/SingleCharacterAnalytics";
import Callback from "./pages/callback";
import Pricing from "./pages/home/Pricing";
import Chats from "./pages/home/Chats";
import Setting from "./pages/home/Setting";
import Users from "./pages/admin/pages/Users";
import Revenue from "./pages/admin/pages/Revenue";
import User from "./pages/admin/pages/User";
import Support from "./pages/home/Support";
import SuccessfullPayment from "./pages/home/SuccessfulPayment";
import PricingModel from "./pages/admin/pages/PricingModel";
import BookishBoyfriend from "./coming";
import RedirectToHome from "./RedirectToHome";
import BoosterManager from "./pages/admin/pages/BoosterQuantity";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/chat/:id" element={<ChatPage />} />
        <Route path="/chat/:id/c/:c" element={<ChatPage />} />
        <Route path="/chat/:id/c/" element={<ChatPage />} />

        <Route path="/chats" element={<Chats />} />
        <Route
          path="/app/a379820595de2c435a868f12ad4d0392"
          element={<RedirectToHome />}
        ></Route>

        <Route path="/saved" element={<Favourite />} />
        <Route path="/price" element={<Pricing />} />
        <Route path="/setting" element={<Setting />} />
        <Route path="/support" element={<Support />} />
        <Route path="/successful-payment" element={<SuccessfullPayment />} />

        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />

        <Route path="/admin" element={<AdminHome />} />
        <Route path="/admin/users" element={<Users />} />
        <Route path="/admin/user/:id" element={<User />} />

        <Route path="/admin/revenue" element={<Revenue />} />
        <Route path="/admin/booster" element={<BoosterManager />} />

        <Route path="/admin/pricing-model" element={<PricingModel />} />

        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/admin/edit-character/:id" element={<EditCharacter />} />
        <Route path="/admin/create-character" element={<CreateCharacter />} />
        <Route
          path="/admin/character-analytics/:id"
          element={<SingleCharacterAnalytics />}
        />

        <Route path="/callback" element={<Callback />} />
      </Routes>
    </div>
  );
}

export default App;
