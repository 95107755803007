import React from "react";
import AdminHeader from "../components/header";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";
import { Link } from "react-router-dom";
import { getUsers } from "../../../api/rest-apis";

interface UsersProps {}

interface UsersState {
  users: any[];
  usersGrowth: any[];
  currentPage: number;
  itemsPerPage: number;
}

class Users extends React.Component<UsersProps, UsersState> {
  state = {
    users: [],
    usersGrowth: [],
    currentPage: 1, // Start from the first page
    itemsPerPage: 25, // Default items per page
  };

  chartData = Array.from({ length: 15 }, (_, index) => {
    const today = new Date();
    const date = new Date(today.getTime() - index * 24 * 60 * 60 * 1000);
    const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;

    const users = Math.round(Math.random() * 40);
    return { date: formattedDate, users };
  });

  async componentDidMount() {
    try {
      const users: any = await getUsers();

      const newArr: any = [];
      Object.keys(users.usersGrowth).map((value) => {
        newArr.push({ date: value, users: users.usersGrowth[value] });
      });

      users.users.reverse();

      this.setState({ users: users.users, usersGrowth: newArr });
    } catch (error) {
      console.error(error);
    }
  }

  // Change the current page
  changePage = (pageNumber: number) => {
    this.setState({ currentPage: pageNumber });
  };

  // Change the items per page
  changeItemsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      itemsPerPage: parseInt(event.target.value),
      currentPage: 1,
    });
  };

  render() {
    const { users, currentPage, itemsPerPage } = this.state;

    // Calculate the index of the first and last user to display
    const indexOfLastUser = currentPage * itemsPerPage;
    const indexOfFirstUser = indexOfLastUser - itemsPerPage;
    const paginatedUsers = users.slice(indexOfFirstUser, indexOfLastUser);

    const totalPages = Math.ceil(users.length / itemsPerPage);

    return (
      <>
        <div className="admin">
          <AdminHeader></AdminHeader>
          <div className="edit-character">
            <div className="title">Users</div>
            <div className="sub-title mt-3">
              Sorted from earliest recent registrations to oldest.
            </div>

            {/* Pagination controls */}
            <div className="pagination-controls w-[10rem]">
              <label
                htmlFor="itemsPerPage"
                className="block text-sm font-medium text-gray-700"
              >
                Items per page:
              </label>
              <select
                id="itemsPerPage"
                value={itemsPerPage}
                onChange={this.changeItemsPerPage}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={200}>200</option>
              </select>
            </div>

            <table className="my-table" style={{ textAlign: "left" }}>
              <thead className="mt-4">
                <tr>
                  <th>NAME</th>
                  <th>PLAN</th>
                  <th>COUNTRY</th>
                  <th>CREATED</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {paginatedUsers.map((value: any, index) => (
                  <tr key={index} style={{ padding: "2rem !important" }}>
                    <td>
                      {value.firstName + " "}
                      {value.lastName ? value.lastName : ""}
                    </td>
                    <td>
                      <span className="table-status">{value.plan}</span>
                    </td>
                    <td>{value.country}</td>
                    <td>
                      {new Date(value.createdAt).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}{" "}
                      {new Date(value.createdAt).toLocaleTimeString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        timeZoneName: "short",
                      })}
                    </td>
                    <td>
                      <Link
                        to={"/admin/user/" + value._id}
                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                      >
                        View
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Pagination controls (bottom) */}
            <div className="pagination-controls mt-4 flex justify-center ">
              <div className="pagination-buttons flex space-x-2">
                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    key={index + 1}
                    className={`px-3 py-1 rounded-md border text-sm ${
                      index + 1 === currentPage
                        ? "bg-indigo-600 text-white"
                        : "bg-white text-gray-700 border-gray-300 hover:bg-gray-200"
                    }`}
                    onClick={() => this.changePage(index + 1)}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="edit-character">
            <div className="title">Users Growth Rate</div>
            <div className="sub-title mt-3">
              Observe the impact of user registration growth over time.
            </div>

            <div className="single-character-chart">
              <LineChart
                width={1200}
                height={500}
                data={this.state.usersGrowth}
              >
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <CartesianGrid strokeDasharray="3 3" />
                <Line type="monotone" dataKey="users" stroke="#8884d8" />
              </LineChart>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Users;
