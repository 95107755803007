import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../helpers/withRouter";
import Header from "../../components/header";
import { getPopularCharacters, getTags, postTags } from "../../api/rest-apis";
import { Character } from "../../interfaces/rest-api";
import truncateParagraph from "../../helpers/truncateParagraph";
import SideMenu from "../../components/sideMenu";
import Spin from "../../helpers/spin";
import NonPremiumCharacterPopup from "../../components/nonPremiumCharacterPopup";

interface HomeProps {
  navigate: any;
}

interface Tag {
  active: boolean;
  tag: string;
}

interface HomeState {
  characters: any[];
  tags: Tag[];
  loading: boolean;
  nonPremiumCharacterClicked: boolean;
  nonPremiumCharacterName: string;
  nonPremiumCharacterId: string;
}

class Home extends React.Component<HomeProps, HomeState> {
  constructor(props: any) {
    super(props);
    this.state = {
      characters: [],
      tags: [],
      loading: false,
      nonPremiumCharacterClicked: false,
      nonPremiumCharacterName: "",
      nonPremiumCharacterId: "",
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    try {
      await this.getPopularCharacters();
      const tags: any = await getTags();
      this.setState({
        tags: tags.map((tag: any) => {
          return { active: false, tag };
        }),
        loading: false,
      });
    } catch (error) {}
  }

  async getPopularCharacters() {
    const response: any = await getPopularCharacters();
    this.setState({ characters: response.result });
  }

  async toggleTag(index: number) {
    try {
      const arr = [...this.state.tags];
      arr[index].active = !arr[index].active;
      this.setState({ tags: arr });

      const tags: any = arr
        .filter((value: any) => value.active)
        .map((tag: any) => tag.tag);
      const DBResonse: any = await postTags({ tags });

      this.setState({
        characters: DBResonse.result,
      });

      // If all tags are off we will show the defualt popular ones
      if (arr.filter((value: any) => !value.active).length === arr.length)
        await this.getPopularCharacters();
    } catch (error) {
      this.componentDidMount();
    }
  }

  render() {
    return (
      <>
        <div className="home-container">
          <SideMenu></SideMenu>

          <div className="main-view lg:ml-6">
            <Header></Header>
            <NonPremiumCharacterPopup
              isPremium={this.state.nonPremiumCharacterClicked}
              characterName={this.state.nonPremiumCharacterName}
              characterId={this.state.nonPremiumCharacterId}
              setOpen={(open: boolean) => {
                this.setState({ nonPremiumCharacterClicked: open });
              }}
            />

            {this.state.loading && (
              <div className="flex justify-center">
                <Spin></Spin>
              </div>
            )}
            {!this.state.loading && (
              <>
                <p className="min-title mt-8">Tags #</p>
                <div className="flex overflow-x-auto">
                  <div className="inline-flex space-x-4 tags">
                    {this.state.tags.map((tag: any, index: number) => (
                      <div
                        key={index}
                        className={tag.active ? "tag active" : "tag"}
                        onClick={() => this.toggleTag(index)}
                      >
                        {tag.tag}
                      </div>
                    ))}
                  </div>
                </div>

                <p className="min-title mt-8">Discover!</p>
                <div className="flex overflow-x-auto">
                  <div className="inline-flex space-x-4">
                    {this.state.characters.map((character: Character) => (
                      <div
                        className="character-card"
                        onClick={() =>
                          character.includes_premium
                            ? this.setState({
                                nonPremiumCharacterClicked: true,
                                nonPremiumCharacterName:
                                  character.character_name,
                                nonPremiumCharacterId: character._id as string,
                              })
                            : this.props.navigate(`/chat/${character._id}`)
                        }
                      >
                        {character.includes_premium && (
                          <span
                            className="beta"
                            style={{
                              position: "relative",
                              left: "-25px",
                              top: "-18px",
                              fontWeight: 900,
                            }}
                          >
                            PREMIUM
                          </span>
                        )}
                        <img src={character.character_profile} alt="" />
                        <p
                          className="character-desc "
                          dangerouslySetInnerHTML={{
                            __html: character.human_description,
                          }}
                        >
                          {/* {truncateParagraph(character.human_description)} */}
                        </p>
                        <p className="character-name">
                          {character.character_name}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>

                <p className="min-title mt-4">Get to know!</p>

                <div className="flex overflow-x-auto">
                  <div className="saying-container inline-flex space-x-4 gap-8 mt-4">
                    {this.state.characters.map((character: Character) => (
                      <div className="saying">
                        <div className="flex">
                          <img src={character.character_profile} alt="" />
                          <div className="flex flex-col justify-center">
                            <p className="saying-character-name ml-3">
                              {character.character_name}
                            </p>

                            <p className="saying-italic ml-3">Try Saying: </p>
                          </div>
                        </div>

                        {character.sample_prompts.map(
                          (prompt: string, index: number) => {
                            if (index < 3) {
                              return (
                                <div
                                  className="sample-messages"
                                  onClick={() =>
                                    this.props.navigate(
                                      `/chat/${character._id}?prompt=${prompt}`
                                    )
                                  }
                                >
                                  <div className="sample-message">{prompt}</div>
                                </div>
                              );
                            }
                          }
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Home);
