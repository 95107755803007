import React from "react";
import ChatHistory from "../../components/chat-history";
import InputBox from "../../components/inputBox";
import withRouter from "../../helpers/withRouter";
import {
  getConversationsByConversationId,
  postChatForCharacter,
} from "../../api/rest-apis";
import { Character } from "../../interfaces/rest-api";
import Popup from "../../components/popup";
import { reloadPage } from "../../helpers/reload";
import { TrackJS } from "trackjs";
import { delay } from "../../helpers/functions";

interface ChatProps {
  id: string;
  c: string;
  action: string;
  updateParams: any;
  higherLevelProps: any;
  character: Character;
  newChat: boolean;
  resetChatFunc: any;
  isVoiceEnabled: boolean;
  updateConversationId: any;
  saved: boolean;
  save: any;
  showHistory: any;
  updateIsVoiceEnabled: any;
  userPlan: string;
  popUpReplace: string;
  haveAnyPrompt: boolean;
  bookTalk: any;
  book_talk_prompts: string[];
}

interface Message {
  message: string;
  right: boolean;
}

interface ChatState {
  history: Message[];
  inputField: string;
  loadingNewChat: boolean;
  conversation_id: string;
  showBothVoiceText: boolean;
  plan: string;
  popUp: boolean;
  reasonForPopUp: string;
  popUpReplace: string;
  newChatReceived: boolean;
  bookTalk: false;
}

class Chat extends React.Component<ChatProps, ChatState> {
  state: ChatState = {
    inputField: "",
    history: [],
    loadingNewChat: false,
    conversation_id: "",
    showBothVoiceText: false,
    plan: "Basic",
    popUp: false,
    reasonForPopUp: "monthly_limit",
    popUpReplace: "",
    newChatReceived: false,
    bookTalk: false,
  };

  chatHistoryRef!: React.RefObject<HTMLDivElement>;

  constructor(props: ChatProps) {
    super(props);
    this.chatHistoryRef = React.createRef();
  }

  scrollToBottom() {
    setTimeout(() => {
      if (this.chatHistoryRef.current) {
        this.chatHistoryRef.current.scrollTop =
          this.chatHistoryRef.current.scrollHeight;
      }
    }, 100); // Adjust the delay as needed
  }

  componentDidMount() {
    this.scrollToBottom();

    if (this.props.c && this.props.c.length > 5) {
      this.getCurrentConversationFromAPI();
    } else {
      this.setState({
        history: [
          {
            message: this.props.character.greeting,
            right: true,
          },
        ],
      });
    }

    // Check for and load voice preferences
    const voiceSettings = JSON.parse(
      localStorage.getItem("voice_settings") || "[]"
    );
    const userSetting = voiceSettings.find((setting: any) =>
      setting.id === this.props.id && setting.c === this.props.c
        ? this.props.c
        : this.state.conversation_id
    );

    if (userSetting) {
      this.setState({ showBothVoiceText: userSetting.showBothVoiceText });
    } else {
      // If not found, add to localStorage
      voiceSettings.push({
        id: this.props.id,
        showBothVoiceText: false,
        c: this.props.c ? this.props.c : this.state.conversation_id,
      });
      localStorage.setItem("voice_settings", JSON.stringify(voiceSettings));
    }
  }

  async componentDidUpdate(prevProps: ChatProps, prevState: ChatState) {
    if (prevProps.c !== this.props.c) {
      await this.getCurrentConversationFromAPI();
      this.scrollToBottom();
      this.setState({ newChatReceived: false });
    }

    if (this.props.newChat) {
      this.setState({
        history: [
          {
            message: this.props.character.greeting,
            right: true,
          },
        ],
        conversation_id: "",
      });

      this.props.resetChatFunc();
    }
  }

  async getCurrentConversationFromAPI() {
    try {
      const response: any = await getConversationsByConversationId(
        this.props.c
      );
      const newArr: any = [];

      for (let i = 0; i < response.length; i++) {
        const message = response[i];
        newArr.push({
          right: message.role === "assistant" ? true : false,
          message: message.content,
        });
      }
      this.setState({ history: newArr });
      this.scrollToBottom();
    } catch (error) {}
  }

  async conversationStartToGetLong(conversation_id: string) {
    await delay(3000);
    // Retrieve all records from localStorage
    const recordsString = localStorage.getItem("recentRecords") || "[]"; // Default to '[]' if null
    const records: any[] = JSON.parse(recordsString);

    function getRecentRecords(records: any[], thisX: any) {
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

      return records.filter((record: any) => {
        const recordDate = new Date(record.date);
        return (
          recordDate < sevenDaysAgo && // Changed this line to check for dates older than 7 days
          record.id === thisX.props.id &&
          record.c === thisX.props.c
        );
      });
    }

    let filtered = records.filter(
      (record: any) =>
        record.id === this.props.id && record.c === conversation_id
    );

    // Filter records from the last 7 days
    let recentRecords = getRecentRecords(records, this);

    if (filtered.length === 0) {
      records.push({
        date: new Date(),
        id: this.props.id,
        c: conversation_id,
        counter: 1,
      });
      localStorage.setItem("recentRecords", JSON.stringify(records));
      // this.setState({
      //   popUp: true,
      //   reasonForPopUp: "starting_long_conversation",
      // }); //  Let's just ingore the first notification, only when it happens two in a row
    } else {
      if (filtered[0].counter === 1) {
        const index = records.findIndex(
          (record) => record.id == this.props.id && record.c == conversation_id
        );

        records[index].counter = 2;
        localStorage.setItem("recentRecords", JSON.stringify(records));

        this.setState({
          popUp: true,
          reasonForPopUp: "starting_long_conversation",
        });
        return;
      }

      if (recentRecords.length === 0) return;

      let index = -1;

      records.map((record: any, index: number) => {
        if (
          record.id === recentRecords[0].id &&
          record.c === recentRecords[0].c
        ) {
          index = index;
        }
      });

      if (index > -1) {
        records[index].date = new Date();
        records[index].counter = records[index].counter++;
      }
      // Optionally update localStorage, if needed
      localStorage.setItem("recentRecords", JSON.stringify(records));
    }
    // Check if there are any recent records that meet the criteria
    if (recentRecords.length > 0) {
      this.setState({
        popUp: true,
        reasonForPopUp: "starting_long_conversation",
      });
    }
  }

  async delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  onInputChange = async (input: string) => {
    try {
      if (!this.state.newChatReceived) {
        this.setState({ newChatReceived: true });
      }
      this.scrollToBottom();

      // Copy the current history array
      const historyArr = [...this.state.history];

      // Add the user's message to the history
      historyArr.push({ message: input, right: false });
      this.setState({ history: historyArr, loadingNewChat: true });

      const conversation: any[] = [];
      historyArr.map((value) => {
        conversation.push({
          role: value.right ? "assistant" : "user",
          content: value.message,
        });
      });

      let payload: any = {
        conversation,
        conversation_id:
          this.props.c && this.props.c.length > 5
            ? this.props.c
            : this.state.conversation_id,
        plan: this.state.plan,
      };

      let response: any;
      let conversationIdResponse: string = "";

      try {
        response = await postChatForCharacter(
          this.props.id,
          this.props.bookTalk,
          payload,
          (streamPayload: any) => {
            if (streamPayload.message) {
              historyArr.push({ message: streamPayload.message, right: true });
            }

            if (streamPayload.longConversationWarning) {
              this.conversationStartToGetLong(streamPayload.conversation_id);
            }

            let updateObj: any = {
              inputField: input,
              history: historyArr,
            };

            if (streamPayload.conversation_id) {
              updateObj.conversation_id = streamPayload.conversation_id;
              conversationIdResponse = streamPayload.conversation_id;
              const { updateConversationId } = this.props;
              updateConversationId(
                streamPayload.conversation_id.length > 5
                  ? streamPayload.conversation_id
                  : ""
              );
            }

            // Update the state with the new history
            this.setState(updateObj);

            this.scrollToBottom();
          },
          async (streamFinished: any) => {
            if (streamFinished) {
              this.setState({ loadingNewChat: false });
              await this.delay(3000);
              const lastMessageAssistant = [...this.state.history].reverse()[0]
                .right;
              if (!this.state.popUp && !lastMessageAssistant) {
                localStorage.setItem(
                  "streamError",
                  JSON.stringify(streamFinished)
                );
                reloadPage(
                  `${window.location.origin}/chat/${this.props.id}/c/${
                    this.props.c
                      ? this.props.c
                      : conversationIdResponse.length > 0
                      ? conversationIdResponse
                      : this.state.conversation_id
                  }`
                );
              }
            }
          }
        );
        return;
      } catch (error: any) {
        if (error.assistant_id_confilct) {
          let popUpReplace: string = "";
          error.plans_used_for_this_conversation
            .map((plan: string) => plan.charAt(0).toUpperCase() + plan.slice(1))
            .forEach((plan: string, index: number) => {
              popUpReplace += index === 0 ? plan : ` and ${plan}`;
            });

          this.setState({
            popUp: true,
            reasonForPopUp: "assistant_id_confilct",
            loadingNewChat: false,
            popUpReplace,
          });

          return;
        }

        if (error.monthly_limit) {
          this.setState({
            popUp: true,
            reasonForPopUp: "monthly_limit",
            loadingNewChat: false,
          });

          return;
        }

        if (error.plus_trail_ended) {
          this.setState({
            popUp: true,
            reasonForPopUp: "plus_trail_ended",
            loadingNewChat: false,
          });

          return;
        }

        if (error.exhausted_current_subscription) {
          this.setState({
            popUp: true,
            reasonForPopUp: "exhausted_current_subscription",
            loadingNewChat: false,
          });

          return;
        }

        this.setState({ loadingNewChat: false });

        if (error.upgrade_needed) return;

        TrackJS.track(`Refresh error: ${JSON.stringify(error)}`);

        localStorage.setItem("refreshErrorCatch", JSON.stringify(error));

        reloadPage(
          `${window.location.origin}/chat/${this.props.id}/c/${
            this.props.c ? this.props.c : this.state.conversation_id
          }`
        );
      } finally {
        this.scrollToBottom();
      }
    } catch (error) {
      this.setState({ loadingNewChat: false });
    }
  };

  updateShowBothVoiceText(showBothVoiceText: boolean) {
    this.setState({ showBothVoiceText });

    const voiceSettings = JSON.parse(
      localStorage.getItem("voice_settings") || "[]"
    );
    const settingIndex = voiceSettings.findIndex((setting: any) =>
      setting.id === this.props.id && setting.c === this.props.c
        ? this.props.c
        : this.state.conversation_id
    );

    if (settingIndex !== -1) {
      voiceSettings[settingIndex].showBothVoiceText = showBothVoiceText;
    } else {
      voiceSettings.push({
        id: this.props.id,
        showBothVoiceText,
        c: this.props.c ? this.props.c : this.state.conversation_id,
      });
    }

    localStorage.setItem("voice_settings", JSON.stringify(voiceSettings));
  }

  render() {
    return (
      <>
        {this.state.popUp && (
          <div className="fixed">
            <Popup
              open={this.state.popUp}
              reasonForPopUp={this.state.reasonForPopUp}
              setOpen={(bool: boolean) => this.setState({ popUp: bool })}
              replace={this.state.popUpReplace}
            ></Popup>
          </div>
        )}

        <div className="chat">
          <div className="hidden md:block absolute top-10 right-0">
            {/* <div className="bookish-input-group  mb-3">
              <p className="bookish-input-label">
                Plan <span className="required">*</span>
              </p>
              <select
                className="bookish-select-box"
                value={this.state.plan}
                onChange={(e) => this.setState({ plan: e.target.value })}
              >
                {["Basic", "Starter", "Plus", "Premium"].map(
                  (plan: any, index) => (
                    <option key={index} value={plan}>
                      {plan}
                    </option>
                  )
                )}
              </select>
            </div> */}

            {this.props.userPlan === "Premium" && this.props.isVoiceEnabled && (
              <div className=" w-[20rem] bookish-input-group ml-auto">
                <div
                  className="bookish-on-off flex"
                  onClick={() =>
                    this.updateShowBothVoiceText(!this.state.showBothVoiceText)
                  }
                  style={{
                    width: "auto",
                    padding: ".2rem .3rem",
                    margin: ".3rem",
                  }}
                >
                  <p className="label mt-1">Always include text</p>
                  <div
                    className={`rectangle ml-auto ${
                      this.state.showBothVoiceText ? "rectangle-active" : ""
                    }`}
                  >
                    <div
                      className={`ellipse ${
                        this.state.showBothVoiceText ? "ellipse-active" : ""
                      }`}
                    ></div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div>
            <p className="character-name md:mt-2">
              {this.props.character?.character_name}
            </p>
            <p
              className="character-desc"
              dangerouslySetInnerHTML={{
                __html: this.props.character?.human_description,
              }}
            >
              {/* <RenderDescription
              style={{ whiteSpace: "pre-line" }}
                inputString={this.props.character?.human_description}
              /> */}
            </p>

            <p
              className="block lg:hidden cursor-pointer"
              style={{ color: "#0070E0", textAlign: "center" }}
              onClick={() => this.props.showHistory()}
            >
              (See chat history )
            </p>

            <div className="flex justify-center gap-3">
              <>
                {!this.props.saved ? (
                  <div
                    className="flex lg:hidden  flex-col align-between cursor-pointer"
                    onClick={() => this.props.save()}
                  >
                    <img
                      src="/assets/images/heart.svg"
                      className="h-6"
                      alt=""
                    />
                  </div>
                ) : (
                  <div
                    className="flex  lg:hidden flex-col align-between cursor-pointer"
                    onClick={() => this.props.save()}
                  >
                    <img
                      src="/assets/images/heart-rose.svg"
                      alt=""
                      className="h-6"
                    />
                  </div>
                )}
              </>
              {this.props.userPlan === "Premium" && (
                <div
                  className="flex lg:hidden  flex-col align-between cursor-pointer"
                  onClick={() => this.props.updateIsVoiceEnabled()}
                >
                  {this.props.isVoiceEnabled ? (
                    <img
                      src="/assets/images/speaking.svg"
                      className="h-6"
                      alt=""
                    />
                  ) : (
                    <img
                      src="/assets/images/speak.svg"
                      className="h-6"
                      alt=""
                    />
                  )}
                </div>
              )}
            </div>
            {this.props.userPlan === "Premium" && this.props.isVoiceEnabled && (
              <div className="flex md:hidden w-[20rem] bookish-input-group mx-auto justify-center">
                <div
                  className="bookish-on-off flex"
                  onClick={() =>
                    this.updateShowBothVoiceText(!this.state.showBothVoiceText)
                  }
                  style={{
                    width: "15rem",
                    padding: ".2rem .3rem",
                    margin: ".3rem",
                  }}
                >
                  <p className="label mt-1" style={{ fontSize: "1rem" }}>
                    Always include text
                  </p>
                  <div
                    className={`rectangle ml-auto ${
                      this.state.showBothVoiceText ? "rectangle-active" : ""
                    }`}
                  >
                    <div
                      className={`ellipse ${
                        this.state.showBothVoiceText ? "ellipse-active" : ""
                      }`}
                    ></div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="center-flex">
            <div className="flex flex-col">
              <div
                className="flex-grow mt-8 conversation-container"
                ref={this.chatHistoryRef}
                style={{ scrollBehavior: "smooth" }}
              >
                <ChatHistory
                  character={this.props.character}
                  history={this.state.history}
                  loading={this.state.loadingNewChat}
                  isVoiceEnabled={this.props.isVoiceEnabled}
                  showBothVoiceText={this.state.showBothVoiceText}
                  userPlan={this.props.userPlan}
                  conversation_id={
                    this.props.c ? this.props.c : this.state.conversation_id
                  }
                  newChatReceived={this.state.newChatReceived}
                />
              </div>
            </div>
          </div>

          <div
            className="input-box-container"
            style={{ display: this.state.popUp ? "none" : "block" }}
          >
            {this.props.action == "new" && (
              <div className="flex flex-col suggestion-prompts">
                {this.props.character.sample_prompts.map((prompt: string) => (
                  <div
                    className="suggestion-prompt mb-2 cursor-pointer"
                    onClick={() => {
                      if (this.props.bookTalk === "true") {
                        this.props.updateParams({ prompt, bookTalk: false });
                        setTimeout(() => window.location.reload(), 100);
                      } else {
                        this.props.updateParams({ prompt, bookTalk: false });
                      }
                    }}
                  >
                    {prompt}
                  </div>
                ))}

                {this.props.book_talk_prompts.length > 0 && (
                  <p
                    className="suggestion-prompt mb-2 cursor-pointer"
                    style={{ border: "none", fontWeight: 900 }}
                  >
                    Book Talk
                  </p>
                )}

                {this.props.book_talk_prompts.map((prompt: string) => (
                  <div
                    className="suggestion-prompt mb-2 cursor-pointer"
                    onClick={() => {
                      if (this.props.bookTalk === "false") {
                        this.props.updateParams({ prompt, bookTalk: true });
                        setTimeout(() => window.location.reload(), 100);
                      } else {
                        this.props.updateParams({ prompt, bookTalk: true });
                      }
                    }}
                  >
                    {prompt}
                  </div>
                ))}
              </div>
            )}

            <div className="the-field-container">
              <InputBox
                onInputChange={(input: string) => this.onInputChange(input)}
                higherLevelProps={this.props}
                haveAnyPrompt={this.props.haveAnyPrompt}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Chat);
