import React from "react";
import { Link } from "react-router-dom";

interface NonPremiumCharacterProps {
  isPremium: boolean;
  characterName: string;
  characterId: string;
  setOpen: (open: boolean) => void;
}

const NonPremiumCharacterPopup: React.FC<NonPremiumCharacterProps> = ({
  isPremium,
  characterName,
  characterId,
  setOpen,
}) => {
  return (
    <>
      {isPremium && (
        <div
          className={`fixed inset-0 px-2 z-10 overflow-hidden flex items-center justify-center transition-opacity duration-300 ${
            isPremium ? "opacity-100" : "opacity-0"
          }`}
          onClick={() => setOpen(false)}
        >
          <div className="absolute inset-0 bg-gray-500 bg-opacity-75"></div>
          <div
            className={`bg-white rounded-md shadow-xl overflow-hidden max-w-md w-full sm:w-96 md:w-1/2 lg:w-2/3 xl:w-1/3 z-50 transition-all duration-300 ${
              isPremium ? "scale-100" : "scale-75"
            }`}
            onClick={(e) => e.stopPropagation()} // Prevent click from closing modal
          >
            {/* Modal Header */}
            <div className="text-white px-4 py-2 flex justify-between items-center bg-primary">
              <h2 className="text-lg font-semibold text-white">Important!</h2>
            </div>
            {/* Modal Body */}
            <div className="p-4 text-center">
              <p className="text-gray-800 text-lg">
                This character, <strong>{characterName}</strong>, and its full
                novella are only available with the Premium plan.
              </p>
              <Link to="/price" className="text-primary underline mt-2">
                Click here to get it now!
              </Link>
              <p className="text-gray-600 mt-4">
                <Link
                  to={`/chat/${characterId}`}
                  className="text-primary underline"
                >
                  Click here
                </Link>{" "}
                to read the novella's sneak peek and description, with the chat
                functionality disabled.
              </p>
            </div>
            {/* Modal Footer */}
            <div className="flex justify-end border-t p-4">
              <button
                onClick={() => setOpen(false)}
                className="px-3 py-1 bg-primary text-white rounded-md"
              >
                Close
              </button>
            </div>
            {/* Close Button */}
            <button
              onClick={() => setOpen(false)}
              className="absolute -top-0 -right-0 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white z-10"
            >
              ✗
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default NonPremiumCharacterPopup;
