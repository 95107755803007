import * as React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/header";
import withRouter from "../../helpers/withRouter";
import Chat from "./Chat";
import toastHelper from "../../helpers/toast";
import {
  deleteConversation,
  getChatInfoForCharacter,
  getConversationsByConversationId,
  getSave,
  postSave,
} from "../../api/rest-apis";
import { Character } from "../../interfaces/rest-api";

import DeleteModalHelper from "../../helpers/delete-modal";
import Spin from "../../helpers/spin";
import MobileChatsSavedHistory from "./components/setting/mobile-chats-saved-history";

interface ChatPageProps {
  updateParams: any;
  id: string;
  c: string;
  navigate: any;
  conversation_id?: any;
  dispatchSetConversationId?: any;
  bookTalk: any;
}

interface HistoryObj {
  conversation_id: string;
  user_question: string;
}

interface ChatPageState {
  character: Character | undefined;
  history: string[];
  sample_prompts: HistoryObj[];
  book_talk_prompts: any[];
  showHistory: boolean;
  newChat: boolean;
  deleteConversationId: string;
  showDeleteModal: boolean;
  saved: boolean;
  isVoiceEnabled: boolean;
  loadingPage: boolean;
  conversation_id: string;
  showMobileChatsSavedHistory: boolean;
  userPlan: string;
}

class ChatPage extends React.Component<ChatPageProps, ChatPageState> {
  state = {
    history: [],
    character: undefined,
    sample_prompts: [],
    book_talk_prompts: [],
    showHistory: true,
    newChat: false,
    deleteConversationId: "",
    showDeleteModal: false,
    saved: false,
    isVoiceEnabled: false,
    loadingPage: false,
    conversation_id: "",
    showMobileChatsSavedHistory: false,
    userPlan: "",
  };

  constructor(props: ChatPageProps) {
    super(props);
    this.resetChat = this.resetChat.bind(this);
    this.updateConversationId = this.updateConversationId.bind(this);
  }

  async componentDidMount() {
    if (!localStorage.getItem("token"))
      window.location.href = `${process.env.REACT_APP_REST_API_URI}/login`;
    this.setState({ loadingPage: true });
    await this.getChat();
    this.setState({ loadingPage: false });

    // Check for voice settings in localStorage
    const voiceEnables = JSON.parse(
      localStorage.getItem("voice_enables") || "[]"
    );
    const userVoiceSetting = voiceEnables.find(
      (setting: any) => setting.id === this.props.id
    );

    // If found, update the state, else initialize with default false
    if (userVoiceSetting) {
      this.setState({ isVoiceEnabled: userVoiceSetting.isVoiceEnabled });
    } else {
      // Add new setting for this user with default isVoiceEnabled as false
      voiceEnables.push({ id: this.props.id, isVoiceEnabled: false });
      localStorage.setItem("voice_enables", JSON.stringify(voiceEnables));
    }
    return;
  }

  updateConversationId(conversation_id: string) {
    this.setState({ conversation_id });
  }

  async getChat() {
    // Make an API request to get the greeting message
    console.log(this.props.c);

    try {
      const response: any = await getChatInfoForCharacter(this.props.id);
      const character = response.character;
      const history: any = character.conversations.map((conversation: any) => {
        // Filtering out only the first user message so we can use it as a title
        const result: any = conversation.conversations.filter(
          (value: any) => value.role === "user"
        );

        if (result.length > 0)
          return {
            conversation_id: conversation.conversation_id,
            user_question: result[0].content,
          };
        return null;
      });

      const cleanHistory = history
        .filter((element: any) => element !== null)
        .reverse();

      this.setState({
        character,
        sample_prompts: character.sample_prompts,
        book_talk_prompts: character.book_talk_prompts,
        history: cleanHistory,
        userPlan: response.userPlan,
      });
    } catch (error) {
      this.setState({ loadingPage: false });
    }

    await this.checkSaved();
  }

  async checkSaved() {
    // Check if it's from the saved one.

    try {
      const response: any = await getSave();
      const found = response.saves.filter(
        (save: any) =>
          save.character_id === this.props.id &&
          save.conversation_id === this.props.c
      );

      if (found.length > 0) {
        return this.setState({ saved: true });
      }

      this.setState({ saved: false });
    } catch (error) {
      this.setState({ saved: false, loadingPage: false });
    }
  }

  async save() {
    const id = toastHelper.loading("Saving ...");
    try {
      const { conversation_id } = this.state;

      await postSave({
        conversation_id:
          conversation_id !== "" ? conversation_id : this.props.c,
        character_id: this.props.id,
      });
      this.setState({ saved: true });
      toastHelper.success("Successfully saved!", id);
    } catch (error) {
      toastHelper.error("Coulnd't save", id);
    }
  }

  resetChat() {
    this.setState({ newChat: false, saved: false });
  }

  updateIsVoiceEnabled(isVoiceEnabled: boolean) {
    // Update component state
    this.setState({ isVoiceEnabled });

    // Update localStorage
    const voiceEnables = JSON.parse(
      localStorage.getItem("voice_enables") || "[]"
    );
    const settingIndex = voiceEnables.findIndex(
      (setting: any) => setting.id === this.props.id
    );

    if (settingIndex !== -1) {
      voiceEnables[settingIndex].isVoiceEnabled = isVoiceEnabled;
    } else {
      voiceEnables.push({ id: this.props.id, isVoiceEnabled });
    }

    localStorage.setItem("voice_enables", JSON.stringify(voiceEnables));
  }

  async deleteConversation() {
    const id = toastHelper.loading("Deleting ...");
    try {
      await deleteConversation(this.state.deleteConversationId);

      toastHelper.success("Conversation have been deleted!", id);
      this.setState({ showDeleteModal: false, deleteConversationId: "" });
      setTimeout(async () => await this.getChat(), 500);
    } catch (error) {
      toastHelper.error("Error on deleting conversation", id);
      this.setState({ showDeleteModal: false, deleteConversationId: "" });
    }
  }

  render() {
    if (this.state.loadingPage) {
      return (
        <div className="flex justify-center mt-8">
          <Spin></Spin>
        </div>
      );
    }

    if (this.state.character && !this.state.loadingPage) {
      return (
        <>
          <div className="chat-page-header">
            <Header></Header>
          </div>
          {this.state.showMobileChatsSavedHistory && (
            <MobileChatsSavedHistory
              closeModal={() =>
                this.setState({ showMobileChatsSavedHistory: false })
              }
              showHistory={this.state.showHistory}
              history={this.state.history}
              checkSaved={() => this.checkSaved()}
              deleteHistory={(payload: any) => this.setState(payload)}
            ></MobileChatsSavedHistory>
          )}
          <div className="chat-container">
            <div className="h-screen flex flex-col chat-side-nav">
              <div className="chat-menu">
                <Link to="/">
                  <div className="flex flex-col align-between">
                    <img src="/assets/images/home.svg" alt="" />

                    <p>Home</p>
                  </div>
                </Link>

                {!this.state.saved && (
                  <div
                    className="flex flex-col align-between"
                    onClick={() => this.save()}
                  >
                    <img src="/assets/images/heart.svg" alt="" />
                    <p>Save</p>
                  </div>
                )}

                {this.state.saved && (
                  <div
                    className="flex flex-col align-between"
                    onClick={() => this.save()}
                  >
                    <img src="/assets/images/heart-rose.svg" alt="" />
                    <p>Saved</p>
                  </div>
                )}

                <div
                  className="flex flex-col align-between"
                  onClick={() => {
                    this.props.navigate(`/chat/${this.props.id}`);
                    this.setState({
                      newChat: true,
                      saved: false,
                      conversation_id: "",
                    });
                  }}
                >
                  <img src="/assets/images/plus.svg" alt="" />
                  <p>New Chat</p>
                </div>

                <div
                  className="flex flex-col align-between"
                  onClick={() => this.props.navigate(`/saved`)}
                >
                  <img src="/assets/images/chat-history.svg" alt="" />
                  <p>Saved</p>
                </div>
              </div>
              {this.state.userPlan === "Premium" && (
                <div className="bookish-input-group mt-7">
                  <div
                    className="bookish-on-off flex"
                    onClick={() =>
                      this.updateIsVoiceEnabled(!this.state.isVoiceEnabled)
                    }
                    style={{ width: "auto", padding: ".7rem .8rem" }}
                  >
                    <p className="label mt-1">Voice response</p>
                    <div
                      className={`rectangle ml-auto ${
                        this.state.isVoiceEnabled ? "rectangle-active" : ""
                      }`}
                    >
                      <div
                        className={`ellipse ${
                          this.state.isVoiceEnabled ? "ellipse-active" : ""
                        }`}
                      ></div>
                    </div>
                  </div>
                </div>
              )}

              {this.state.sample_prompts.length > 0 && (
                <p className="jump">
                  {this.state.userPlan === "Plus" ||
                  this.state.userPlan === "Premium" ? (
                    <>Personality Mode</>
                  ) : (
                    <>Jump into a Conversation</>
                  )}
                </p>
              )}

              <div className="flex-grow overflow-auto max-h-[40%]">
                {this.state.sample_prompts.map((prompt: string) => (
                  <div
                    key={prompt} // Add a unique key for each prompt
                    className="sample-prompt"
                    onClick={() => {
                      if (this.props.bookTalk === "true") {
                        this.props.updateParams({ prompt, bookTalk: false });
                        setTimeout(() => window.location.reload(), 100);
                      } else {
                        this.props.updateParams({ prompt, bookTalk: false });
                      }
                    }}
                  >
                    {prompt}
                  </div>
                ))}
              </div>
              {this.state.book_talk_prompts.length > 0 && (
                <p className="jump">Book Talk</p>
              )}

              <div className="flex-grow overflow-auto">
                {this.state.book_talk_prompts.map((prompt: string) => (
                  <div
                    key={prompt} // Add a unique key for each prompt
                    className="sample-prompt"
                    onClick={async () => {
                      console.log(this.props.bookTalk);
                      if (
                        this.props.bookTalk === "false" ||
                        this.props.bookTalk === null
                      ) {
                        this.props.updateParams({ prompt, bookTalk: true });
                        setTimeout(() => window.location.reload(), 100);
                      } else {
                        this.props.updateParams({ prompt, bookTalk: true });
                      }
                    }}
                  >
                    {prompt}
                  </div>
                ))}
              </div>
              {this.state.history.length > 0 && <p className="jump">History</p>}
              <div className="flex-grow overflow-auto">
                {this.state.showHistory &&
                  this.state.history.map((value: any, index) => (
                    <div className="flex">
                      <div
                        key={index}
                        className="sample-prompt border-b border-gray-200 py-2 px-4 cursor-pointer"
                        onClick={() => {
                          this.props.navigate(
                            `/chat/${this.props.id}/c/${value.conversation_id}`
                          );
                          this.checkSaved();
                        }}
                      >
                        {value.user_question}
                      </div>
                      <div
                        className="ml-auto flex align-center cursor-pointer"
                        onClick={() =>
                          this.setState({
                            showDeleteModal: true,
                            deleteConversationId: value.conversation_id,
                          })
                        }
                      >
                        <img
                          src="/assets/images/delete-red.svg"
                          alt=""
                          className="h-5 m-auto"
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <div className="chat-main">
              <Chat
                character={this.state.character}
                newChat={this.state.newChat}
                resetChatFunc={this.resetChat}
                isVoiceEnabled={this.state.isVoiceEnabled}
                updateConversationId={this.updateConversationId}
                saved={this.state.saved}
                save={() => this.save()}
                showHistory={() =>
                  this.setState({ showMobileChatsSavedHistory: true })
                }
                updateIsVoiceEnabled={() =>
                  this.updateIsVoiceEnabled(!this.state.isVoiceEnabled)
                }
                userPlan={this.state.userPlan}
                haveAnyPrompt={
                  this.state.book_talk_prompts.length > 0 ||
                  this.state.sample_prompts.length > 0
                }
                book_talk_prompts={this.state.book_talk_prompts}
              ></Chat>
            </div>
          </div>

          {this.state.showDeleteModal && (
            <>
              <DeleteModalHelper
                title={"Delete Conversation"}
                message={"Are you sure you want to delete the conversation ? "}
                close_modal={() =>
                  this.setState({
                    showDeleteModal: false,
                    deleteConversationId: "",
                  })
                }
                delete_it={() => this.deleteConversation()}
              />
            </>
          )}
        </>
      );
    }
  }
}
export default withRouter(ChatPage);
